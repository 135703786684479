<template>
  <div></div>
</template>

<script>
export default {
  data () {
    return {
      error: false,
    }
  },
  async created () {
    await this.$store.dispatch("logout")
      .then(response => this.error = response)
      .catch(error => this.error = error)
    this.$router.push("/login")
  }
}
</script>
